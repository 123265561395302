import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { Context } from "../AppContext";

function Wrapper({ show, children, className, ...props }) {
  return (
    <CollapseWrapper
      className={`${className}  ${show ? "show" : ""}`}
      {...props}
    >
      {children}
    </CollapseWrapper>
  );
}

function Categories({ show, hideCollapse, ...props }) {
  const app = React.useContext(Context);
  const { categories } = app.state;

  const count = 6;

  const _goToCat = cat => {
    navigate(`/products?tab=全部商品&category=${cat.name}`);
    hideCollapse();
  };

  return (
    <Wrapper show={show} {...props}>
      <Node
        style={{
          color: Theme.colors.sub,
          fontSize: 14,
          cursor: "default",
        }}
      >
        全部商品
      </Node>
      <div style={{ display: "flex" }}>
        {/* /// 3 column, 6 row */}
        {[...Array(3).keys()].map(column => (
          <div style={{ marginRight: 10 }} key={column}>
            {categories
              .slice(column * count, (column + 1) * count)
              .map((c, idx) => (
                <Node
                  key={idx}
                  style={{ fontSize: 12 }}
                  onClick={() => _goToCat(c)}
                >
                  {c.display}
                </Node>
              ))}
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
Categories = connect(null, ActionCreator)(Categories);

function Hashtags({ show, hideCollapse, ...props }) {
  const app = React.useContext(Context);
  const { hashtags } = app.state;

  const count = 6;
  const _goToHashtag = hashtag => {
    navigate(`/products/?tab=Hashtags&hashtag=${encodeURI(hashtag)}`);
    hideCollapse();
  };

  return (
    <Wrapper show={show} className="categories" {...props}>
      <div style={{ display: "flex" }}>
        {/* /// 3 column, 6 row */}
        {[...Array(3).keys()].map(column => (
          <div style={{ marginRight: 10 }} key={column}>
            {hashtags
              .slice(column * count, (column + 1) * count)
              .map((c, idx) => (
                <Node
                  key={idx}
                  style={{ fontSize: 12, minWidth: 120 }}
                  onClick={() => _goToHashtag(c)}
                >
                  {c}
                </Node>
              ))}
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
Hashtags = connect(null, ActionCreator)(Hashtags);

function PromoZones({ show, hideCollapse, ...props }) {
  const app = React.useContext(Context);
  const promoItems = app.state.promoItems.filter(x => x.type === "bottom_zone");
  const count = 6;

  const _goToPromoZone = zone => {
    navigate(`/products?tab=活動專區&promozone=${zone.name}`);
    hideCollapse();
  };

  return (
    <Wrapper show={show} {...props}>
      <div style={{ display: "flex" }}>
        {/* /// 3 column, 6 row */}
        {[...Array(3).keys()].map(column => (
          <div style={{ marginRight: 10 }} key={column}>
            {promoItems
              .slice(column * count, (column + 1) * count)
              .map((c, idx) => (
                <Node
                  key={idx}
                  style={{ fontSize: 12, minWidth: 120 }}
                  onClick={() => _goToPromoZone(c)}
                >
                  {c.label}
                </Node>
              ))}
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
PromoZones = connect(null, ActionCreator)(PromoZones);

const CollapseWrapper = styled.div`
  z-index: 10;
  padding: 30px 40px;
  background-color: ${props =>
    (props?.style?.backgroundColor && props.style.backgroundColor) ||
    "#3c3c3c"};
  box-shadow: 0px 2px 8px -4px #555;
  position: absolute;
  top: ${props => (props.top && props.top) || Theme.navbarHeight}px;
  right: 0;
  min-width: 200px;
  width: 100%;
  height: auto;

  @media only screen and (max-width: ${Theme.breakpoints.lg}px) {
    width: 100%;
    top: ${Theme.navbarHeightMore}px;
  }

  transform-origin: top center;
  transform: scaleY(0);
  opacity: 0.2;
  transition: all 0.2s ease-in-out;

  &.show {
    transform: scaleY(1);
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
`;

const Node = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  color: #e6e6e6;

  :hover {
    color: #9b9b9b;
  }
`;

export { Categories, Hashtags, PromoZones };
