import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import * as Theme from "../Theme";

function SortingDropdown(props) {
  return (
    <Wrapper>
      <Ant.Select
        value={props.sortValue}
        style={{
          width: 160,
          fontSize: 12,
          borderColor: Theme.colors.main,
        }}
        onChange={sortValue => props.setSortValue(sortValue)}
      >
        <Ant.Select.Option value="+updated" style={{ fontSize: 12 }}>
          依日期排列(由舊到新)
        </Ant.Select.Option>
        <Ant.Select.Option value="-updated" style={{ fontSize: 12 }}>
          依日期排列(由新到舊)
        </Ant.Select.Option>
        <Ant.Select.Option value="+price" style={{ fontSize: 12 }}>
          依價位排列(由低到高)
        </Ant.Select.Option>
        <Ant.Select.Option value="-price" style={{ fontSize: 12 }}>
          依價位排列(由高到低)
        </Ant.Select.Option>
      </Ant.Select>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default SortingDropdown;
