import React from "react";
import styled from "styled-components";
import { DesignersArr } from "../DesignersArr";

class Designers extends React.Component {
  render() {
    let { onClick } = this.props;
    return (
      <div>
        {DesignersArr.map(i => (
          <Item key={i.name} onClick={() => onClick(i)}>
            {i.name || "unknown"}
          </Item>
        ))}
      </div>
    );
  }
}

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
  cursor: pointer;
  color: #e6e6e6;

  :hover {
    color: #9b9b9b;
  }
`;

export default Designers;
