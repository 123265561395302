import React from "react";
import styled from "styled-components";
import DesignerItem from "./DesignerItem";
import DesignerDetail from "./DesignerDetail";
import ProductsGrid from "../../Components/ProductsGrid";
import * as Theme from "../../Theme";
import BreadCrumbs from "../../Components/Breadcrumb";
import { DesignersArr } from "../../DesignersArr";

const queryString = require("query-string");

const padItems = Array.from({ length: 6 });

function DesignerTab(props) {
  const { rwd, onSelectDesigner, products: _products } = props;
  const [selectedDesigner, setSelectedDesigner] = React.useState(null);

  // let products = selectedDesigner ? _products.slice(0, 5) : _products;
  let products = _products;

  function getSelectedNameFromUrl() {
    try {
      const params = queryString.parse(window.location.search);
      return params.designer;
    } catch (ex) {
      console.warn(ex);
      return "";
    }
  }

  return (
    <Wrapper>
      {getSelectedNameFromUrl() ? (
        <div className="detail">
          {/* designer detail */}
          <DesignerDetail
            item={DesignersArr.find(
              designer => designer.name === getSelectedNameFromUrl()
            )}
            rwd={rwd}
          />
        </div>
      ) : (
        <div className="designer-list">
          <h2>設計師專區</h2>
          <BreadCrumbs
            routes={[
              { label: "線上訂購", link: "/products" },
              { label: "設計師專區", link: `/products?tab=設計師專區` },
            ]}
            style={{ justifyContent: "center" }}
            color="#3C3C3C"
          />
          <div className="grid">
            {/* designer list */}
            {[...DesignersArr, ...padItems].map((item, idx) => (
              <DesignerItem
                key={idx}
                rwd={rwd}
                item={item}
                onClick={() => {
                  setSelectedDesigner(item);
                  onSelectDesigner(item.name);
                }}
              />
            ))}
          </div>
        </div>
      )}

      {!getSelectedNameFromUrl() && (
        <h2
          style={{
            paddingTop: 50,
            borderTop: "1px solid #ccc",
          }}
        >
          全部設計師作品
        </h2>
      )}
      <div className="product-grid" style={{ marginTop: 50 }}>
        <ProductsGrid
          products={products}
          hashtag={
            getSelectedNameFromUrl() ? `@${getSelectedNameFromUrl()}` : "@"
          }
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: PingFangTC;

  & > h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.75;
    text-align: center;
    color: #3c3c3c;
  }

  & > p {
    font-size: 12px;
    line-height: 2.17;
    text-align: center;
    color: #707070;
  }

  & > .line {
    height: 1px;
    background-color: #9b9b9b;
  }

  & > .detail {
    max-width: 760px;
    margin: 0 auto;
  }

  & > .designer-list {
    & > h2 {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.75;
      text-align: center;
      color: #3c3c3c;
    }
    & > .grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  & > .product-grid {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;
  }
`;

export default DesignerTab;
