import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import * as Ant from "antd";
import * as Theme from "../Theme";
import SortingDropdown from "./SortingDropdown";
import useDimension from "../hooks/use-dimension";
import { Search2 } from "@styled-icons/remix-line/Search2";
import BreadCrumbs from "./Breadcrumb";

const queryString = require("query-string");

function ProductTabsHeader(props) {
  const { rwd } = useDimension();
  let { displayTitle, sort, setOpenSearch, search, tab = "全部商品" } = props;

  return (
    <Wrapper rwd={rwd}>
      <div className="title">{displayTitle}</div>

      <BreadCrumbs
        routes={[
          {
            label: "線上訂購",
            link: `/products`,
          },
          {
            label: tab,
            link: `/products?tab=${tab}`,
          },
        ]}
        style={{ justifyContent: "center" }}
        color="#3C3C3C"
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          className="search-button"
          style={{}}
          onClick={() => setOpenSearch(prev => !prev)}
        >
          <div style={{ marginRight: 8 }}>搜尋商品</div>
          <Search2 size={16} />
        </button>
        {search && <div style={{ textAlign: "center" }}>搜尋：{search}</div>}
      </div>

      <Ant.Row className="features">
        <SortingDropdown
          sortValue={sort}
          setSortValue={value => {
            const params = queryString.parse(window.location.search) || {};
            params.sort = value;
            let query = queryString.stringify(params);

            navigate(`/products?${query}`);
          }}
        />
      </Ant.Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .features {
    display: flex;
    margin: ${props => (props.rwd === "mobile" ? "16px 0px" : "0px")};
    justify-content: ${props =>
      props.rwd === "mobile" ? "center" : "flex-end"};
  }
  & > .title {
    ${Theme.textTitleStyle}
    margin-top: 38px;
    text-align: center;
  }

  & .search-button {
    margin-right: 8px;
    border: 0;
    background-color: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    :active {
      color: #444;
      transition: color 0.3s ease-in;
    }
  }
`;

export default ProductTabsHeader;
