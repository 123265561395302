import React from "react";
import styled from "styled-components";
import * as Theme from "../../Theme";
import BreadCrumbs from "../../Components/Breadcrumb";

function DesignerDetail(props) {
  const { item, rwd } = props;

  return (
    <Wrapper>
      <div className="col" style={{ marginBottom: 40 }}>
        <h2>{item.name}</h2>
        <BreadCrumbs
          routes={[
            { label: "線上訂購", link: "/products" },
            { label: "設計師專區", link: `/products?tab=設計師專區` },
            {
              label: item.name,
              link: `/products?tab=設計師專區&designer=${item.name}`,
            },
          ]}
          style={{ justifyContent: "center" }}
          color="#3C3C3C"
        />
        <img src={item.image} alt="designer avatar" />
        <div style={{ flex: 1 }} />
        <div className="share">
          {item.facebook && (
            <SocialBtn name="facebook" href={item.facebook} target="_blank">
              <div className="btn" />
            </SocialBtn>
          )}
          {item.twitter && (
            <SocialBtn name="twitter" href={item.twitter} target="_blank">
              <div className="btn" />
            </SocialBtn>
          )}
          {item.instagram && (
            <SocialBtn name="instagram" href={item.instagram} target="_blank">
              <div className="btn" />
            </SocialBtn>
          )}
          {item.behance && (
            <SocialBtn name="behance" href={item.behance} target="_blank">
              <div className="btn" />
            </SocialBtn>
          )}
          {item.site && (
            <SocialBtn name="site" href={item.site} target="_blank">
              <div className="btn" />
            </SocialBtn>
          )}
        </div>
      </div>
      <p>{item.description || "-----"}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    white-space: pre-line;
    line-height: 28px;
    text-align: center;
  }
  & > .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 20px;
      margin-top: 40px;
    }
    & > h2 {
      color: ${Theme.colors.main};
      font-weight: lighter;
      margin: 0;
      padding-top: 30px;
    }

    & > .share {
      display: flex;
    }
  }
`;

const SocialBtn = styled.a`
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  & > .btn {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props => `url('../../images/${props.name}.png')`};
  }
  & > .btn:hover {
    background-image: ${props => `url('../../images/${props.name}-hover.png')`};
  }
`;

export default DesignerDetail;
