import React from "react";
import styled from "styled-components";

function CategoryMenuItem(props) {
  const { categories, goToCategory } = props;

  return (
    <Wrapper>
      {categories.map(node => {
        return (
          <div className="menu-item">
            <div
              style={{ flex: 1, fontSize: 12 }}
              onClick={e => {
                goToCategory(node);
              }}
            >
              {node.display}
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .menu-item {
    display: flex;
    align-items: center;
    height: 32px;
    cursor: pointer;
    color: #e6e6e6;

    :hover {
      color: #9b9b9b;
    }
  }
`;

export default CategoryMenuItem;
