const DesignersArr = [
  {
    name: "Gao Yan 高 妍",
    description: `高 妍（Gao Yan／ガオ イェン） Illustration & Manga
      1996年生於臺灣臺北，臺灣藝術大學視覺傳達系畢業，於沖繩縣立藝術大學美術系短期留學。 
      創作方式以插畫及漫畫等表現形式呈現，主題多為生活中的呢喃、自然、山與海。
      現為職業插畫家及漫畫家，在台灣日本接案工作，現與感官文化有商業合作等陸續公開。`,
    image: `/images/designer/高妍.jpg`,
    facebook: "https://www.facebook.com/gaoyann/",
    instagram: "https://www.instagram.com/_gao_yan/",
    twitter: "https://twitter.com/_gao_yan",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
  },
  {
    name: "三虫印刷社",
    description: `2018年以《昆蟲印刷指南》一書獲得金點概念設計獎：年度最佳設計獎。 
  
  
      本書創作的目的是希望改變現階段印刷類書籍晦澀並缺乏統整性的現況，
      藉由昆蟲構造、色澤紋路，將印刷理論以生動、統整性的圖文呈現。
      顛覆現有印刷書設計思路的同時，也為沒有上過印刷課或即將邁入職場的設計人，
      打造一本教育性與實用性並存的印刷指南。 
      
      
      2019年延續對於昆蟲與印刷的關注，與感官文化印刷合作出版昆蟲徽章單入裝與精裝版，
      以紙材玩出各種可能的結構與變化。其中精裝版獲得 Topawards Asia 2019八月優秀包裝設計獎。
       `,
    image: `/images/designer/三虫印刷社.jpg`,
    facebook: "https://www.facebook.com/printingonthetiny/",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
  },
  {
    name: "紙上行旅 Teng Yu",
    description: `台灣插畫家、平面設計師。2013 年成立個人工作室 Teng Yu Lab 與插畫設計品牌 紙上行旅 Paper Travel，
      著有《紙上行旅的移動風景》、《回家》和《勇敢挑戰未知》 等圖文作品。喜愛紙張與印刷。`,
    image: `/images/designer/紙上.jpg`,
    site: "https://tengyulab.com/",
    facebook: "https://www.facebook.com/tengyu.tw",
    instagram: "https://www.instagram.com/tengyu.tw",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
  },
  {
    name: "Raimochi",
    description: `插畫家、平面設計師。生活範圍由音樂、色彩和文字組成，
      正在朝色彩の魔術師稱號邁進的普通插畫家，自由接案中，希望不會餓死街頭。`,
    image: `/images/designer/Rai.jpg`,
    facebook: "https://www.facebook.com/raimochi/",
    instagram: "https://www.instagram.com/raimochi/",
    behance: "https://www.behance.net/raimochi",
    site: "https://rai-wang.persona.co/",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
  },
  {
    name: "時間博物館 Time Museum",
    description: `這是一個「探索記憶，回溯與觀看」的計畫…
      探索記憶，回溯與觀看。
      
      
      一個年代的發展與蓬勃豐饒，隨著時間的推進演繹成為獨特的風貌，
      活在科技游刃的現代如此便利與快速，那些在時代發展下過境的產物讓念舊的靈魂有了情有所遞的棲所。
      
      
       這一次，我們往回追溯，找尋那些在家族根源，與生活時間場域遺留下來的物件，以實驗的概念與方式來呈現。
      一座時間博物館，所乘載的盡是時代事件、時代需求下的產物媒介，時間是博物館脈絡起源，
      物件則是時間洪流中漂流的痕跡與信物，將概念與形式結合，邀請大家一起觀賞這座名為時間的博物館。
      `,
    image: `/images/designer/時間博物館.jpg`,
    facebook: "https://www.facebook.com/Timemuseum.fans/",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
  },
  {
    name: "響到你 Track Back to",
    description: `《響到你》是亞洲大學視覺傳達設計學系的畢業製作，以臺灣沒落的傳統產業作為主要的紀錄對象，
      包含打鐵、西服訂製、粧佛；並實地錄製產業工作聲音製成混音歌曲，
      試圖以紀念專輯之形式、黑膠唱片的尺寸規格保存臺灣逐漸沒落的產業面貌。 
      
      
      如此嚴謹的踏查成果、完成度極高的視覺並由原艾音樂進行專業的混音製作，
      也讓他們榮獲金點新秀設計創業潛力特別獎與2019德國紅點設計獎。
      三張專輯、三個產業、三種設計語言重整亮相，一同響起那燦爛的黃金年代。`,
    facebook: `https://www.facebook.com/%E9%9F%BF%E5%88%B0%E4%BD%A0-Track-Back-to-2329652260652090/`,
    image: `/images/designer/響到你.jpg`,
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
  },
  {
    name: "溫皮 Warmpy",
    description: `有多年雜誌設計經驗，在工作中接觸許多動物相關議題，擅於以設計及插畫轉化資訊與讀者溝通。
    
    喜歡運用鮮豔的色彩，注入一點點故事，期望用可愛溫暖的插畫包裝沈重議題，以同理與共存看待世界，從動物的故事裡，找到自己。`,
    image: "/images/designer/溫皮.jpg",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    facebook: "https://www.facebook.com/HsihuiLinillustration",
    instagram: "https://www.instagram.com/hsihuilinillustration/",
    behance: "https://www.behance.net/shihuilinluna",
  },
  {
    name: "Lulu Lin",
    description: `Lulu 現為獨立藝術工作者，專長插畫。曾於德國、荷蘭、丹麥進修設計，除設計作品，其插畫專頁在臉書及Instagram上各以「在家工作者」及「dig a hole」(@da__h_) 為名，進行各種形式的創作。現為獨立藝術工作者，自由接案。
  
    插畫曾登於挪威週報頭版、德國南德意志報周刊、Adobe Creative、WeTransfer、It’s Nice That等。客戶從Rimowa、Camper，到獨立雜誌、品牌，也曾與勤美誠品、but.(we love butter)合作。`,
    image: "/images/designer/Lulu Lin 頭貼.jpeg",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    facebook: "https://www.facebook.com/dahdigahole",
    instagram: "https://www.instagram.com/da__h_/",
  },
  {
    name: "氵彗㣺",
    description: `氵彗㣺，唸作水慧，新北中和人。
    徜徉在個人體驗以及各式概念中去發展個體圖像與符號。`,
    image: "/images/designer/氵彗㣺-大頭貼.jpg",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    facebook: "https://www.facebook.com/w.huijun",
    instagram: "https://www.instagram.com/w.huijun_/",
    twitter: "https://twitter.com/whuijun_",
  },
  {
    name: "日安焦慮",
    description: `2014年開始在繪畫之餘創作漫畫，喜歡在虛幻裡抒發現實困境和多餘的想像。`,
    image: "/images/designer/日安焦慮 頭貼.png",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    facebook: "https://www.facebook.com/morning.anxiety",
    instagram: "https://www.instagram.com/dingpaoyen/",
  },
  {
    name: "瑪魯 Maru",
    description: `瑪魯，來自臺灣臺南的創作者，目前為自由接案工作人。
    創作多為漫畫和插畫，偶爾清淡的描繪一個人內心糾結與不安分的，在哪裡飄泊著的故事。`,
    image: "/images/designer/頭貼_瑪魯.png",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    facebook: "https://www.facebook.com/js94000",
    instagram: "https://www.instagram.com/maru_2a/",
    site: "https://js94000.wixsite.com/my-site",
    twitter: "https://twitter.com/js94000",
  },
  {
    name: "劉倩帆 Chien-Fan Liu",
    description: `插畫與漫畫創作者。曾於獲法國安古蘭國際漫畫節數位漫畫第二名、瑞士洛桑漫畫節新秀獎銀獎，喜愛沒有規則的漫畫形式和樸拙的畫風，作品曾被收錄於斯洛維尼亞Stripburger漫畫雜誌，同時也是波音漫畫誌BO_ING COMIX創辦人之一。`,
    image: "/images/designer/劉倩帆 Chien-Fan Liu 頭像2.jpg",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    instagram: "https://www.instagram.com/cfan.liu/",
  },
  {
    name: "羅荷 He Lo",
    description: `羅荷 He Lo ，現為一名2D動畫、漫畫、插畫的自由接案者。
    作品時常以手繪及幾何勻稱的構圖特質描繪時間、記憶、生活的塵埃以及奇特的世界觀。
    代表作為短篇漫畫《禮物》、動畫mv《兔子洞》。`,
    image: "/images/designer/羅荷 He Lo 頭貼.png",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    facebook: "https://www.facebook.com/hollylo66",
    instagram: "https://www.instagram.com/hollylo33124/",
    site: "https://lo-he.tumblr.com/",
    twitter: "https://twitter.com/hollylo33124",
  },
  {
    name: "SO SHY SO PANIC",
    description: `低調幽默、另類思考、肩頸痠痛`,
    image: "/images/designer/soshysopanic.png",
    banner: "product_list_banner.webp",
    banner_rwd: "product_list_banner.webp",
    instagram: "https://www.instagram.com/soshysopanic.vip",
  },
];

export { DesignersArr };
