import React from "react";
import styled from "styled-components";

class ProductHashtags extends React.Component {
  render() {
    const { hashtags, selected, onTagClick, supportToggle } = this.props;

    return (
      <Wrapper>
        {hashtags.map((t, idx) => {
          return (
            <Tag
              key={idx}
              selected={t === selected}
              onClick={() => {
                if (supportToggle) {
                  if (t === selected) {
                    onTagClick("");
                  } else {
                    onTagClick(t);
                  }
                } else {
                  onTagClick(t);
                }
              }}
            >
              {`#${t}`}
            </Tag>
          );
        })}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  color: #e6e6e6;
  padding: 3px 8px;
  font-size: 12px;
  border: 1px solid #ddd;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  :hover {
    color: #9b9b9b;
    border: 1px solid #9b9b9b;
  }
  ${props =>
    props.selected &&
    `
    background-color: #C89664;
    color: white;
  `}
`;

export default ProductHashtags;
