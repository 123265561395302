import React from "react";
import styled from "styled-components";

function DesignerItem(props) {
  const { rwd, item, onClick } = props;

  let size = 80;
  let padding = 5;

  if (rwd === "pad") {
    size = 100;
    padding = 20;
  } else if (rwd === "desktop") {
    size = 120;
    padding = 40;
  }

  if (!item) {
    return (
      <DesignerItemWrapper size={size} padding={padding} onClick={onClick}>
        <div className="placeholder" />
      </DesignerItemWrapper>
    );
  }

  return (
    <DesignerItemWrapper size={size} padding={padding} onClick={onClick}>
      <img src={item.image} alt="designer face" />

      <div className="detail">
        <h3>{item.name}</h3>
      </div>
    </DesignerItemWrapper>
  );
}

const DesignerItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding}px;
  cursor: pointer;

  & > .placeholder {
    width: ${props => props.size}px;
    height: 1px;
  }

  & > img {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }

  & > .detail {
    width: ${props => props.size}px;

    & > h3 {
      margin: 0 0 10px;
      text-align: center;
    }

    & > p {
      margin: 0;
      text-align: center;
    }
  }
`;

export default DesignerItem;
