import React from "react";
import styled from "styled-components";
import ProductItem from "./ProductItem";
import { Context } from "../AppContext";
import { navigate } from "gatsby";

const slugify = require("uslug");

export default function ProductGrid(props) {
  let { products } = props;
  const app = React.useContext(Context);

  return (
    <Wrapper>
      {products.map((p, idx) => {
        return (
          <ProductItem
            key={idx}
            product={p}
            spec={app.actions.getProductFromSpec({
              productName: p.name,
            })}
            onClick={() => {
              app.actions.setLoading(true);
            }}
            link={`/product/${p.id}`}
          />
        );
      })}

      {/* compensate blank item for ui: justfy-content: space-between */}
      {[0, 1, 2].map(e => (
        <ProductItem key={e} product={null} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
`;
