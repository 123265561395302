import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import * as Ant from "antd";
import * as Theme from "../../Theme";
import styled from "styled-components";
import CategoryMenuItem from "../../Components/CategoryMenuItem";
import ProductHashtags from "../../Components/ProductHashtags";
import PromoZones from "../../Components/PromoZones";
import Designers from "../../Components/Designers";
import ProductsTabHeader from "../../Components/ProductsTabHeader";
import ProductsGrid from "../../Components/ProductsGrid";
import useDimension from "../../hooks/use-dimension";
import * as Collapse from "../../Components/Collapse";
import DesignerTab from "./DesignerTab";
import { Context } from "../../AppContext";
import StaticImage from "../../Components/StaticImage";
import { DesignersArr } from "../../DesignersArr";
import { pricingTypes } from "../../pricingTypes";
import SearchBar from "../../Components/SearchBar";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { KeyboardArrowUp } from "@styled-icons/material/KeyboardArrowUp";

const queryString = require("query-string");

const TABS = {
  PRODUCTS: "全部商品",
  DESIGNERS: "設計師專區",
  PROMOS: "活動專區",
  HASHTAGS: "Hashtags",
  PERIOD: "訂閱制專區",
  CUSTOMIZE: "客製化專區",
  STICKER: "客製化貼紙",
};

function ProductListPage(props) {
  const { dimension, rwd } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [openCollapseType, setOpenCollapseType] = React.useState(null);
  const [openSearch, setOpenSearch] = React.useState(false);

  const app = React.useContext(Context);
  const promoZones = app.state.promoItems.filter(p => p.type === "bottom_zone");

  const {
    products,
    tab,
    hashtag,
    cat,
    sort,
    promozone,
    search,
  } = useMemo(() => {
    const {
      tab = TABS.PRODUCTS,
      search = "",
      category = "",
      hashtag = "",
      designer = "",
      sort = "-updated",
      promozone = "",
    } = queryString.parse(props.location.search);

    const fnSort = ({ key, desc = false }) => (p1, p2) => {
      const k1 = p1[key];
      const k2 = p2[key];

      const factor = desc ? -1 : 1;
      if (k1 === k2) {
        return 0;
      } else if (k1 > k2) {
        return 1 * factor;
      } else {
        return -1 * factor;
      }
    };

    let periodProductNames = [];
    if (
      tab === TABS.PERIOD &&
      app.state.spec &&
      Array.isArray(app.state.spec["common"])
    ) {
      periodProductNames = app.state.spec["common"]
        .filter(p => p.is_period)
        .map(p => p.name);
    }

    let result = records
      .filter(
        p =>
          app.state.productNames.indexOf(p.name) > -1 &&
          (search ? p.name.indexOf(search) > -1 : true) &&
          (tab === TABS.PROMOS
            ? promozone
              ? p.promo_zone?.indexOf(promozone) > -1
              : !!p.promo_zone
            : true) &&
          (tab === TABS.HASHTAGS && hashtag
            ? p.hashtag?.indexOf(hashtag) > -1
            : true) &&
          (tab === TABS.DESIGNERS
            ? p.hashtag?.indexOf(`@${designer}`) > -1
            : true) &&
          (tab === TABS.PERIOD
            ? periodProductNames.indexOf(p.name) > -1
            : true) &&
          (tab === TABS.PRODUCTS && category
            ? p.label?.indexOf(category) > -1
            : true) &&
          (tab === TABS.CUSTOMIZE ? p.label?.indexOf(tab) > -1 : true) &&
          (tab === TABS.STICKER ? p.label?.indexOf(tab) > -1 : true)
      )
      .sort(
        fnSort({
          key: sort.slice(1),
          desc: sort.slice(0, 1) === "-" ? true : false,
        })
      );

    return {
      products: result,
      tab,
      hashtag,
      cat: app.state.categories.find(c => c.name === category),
      sort,
      search,
      promozone: promoZones.find(z => z.name === promozone),
    };
  }, [
    records,
    props.location.search,
    app.state.productNames,
    app.state.categories,
  ]);

  const getRecords = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      const products = await app.actions.getProducts({});
      // only display products mentioned in spec
      setRecords(products);
      app.actions.setDelighter(true);
    } catch (ex) {
      //bypass
    }
    app.actions.setLoading(false);
  }, []);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const getHoverProps = tabName => {
    if (
      rwd === "desktop" &&
      (tabName === TABS.PRODUCTS ||
        tabName === TABS.PROMOS ||
        tabName === TABS.HASHTAGS)
    ) {
      return {
        onMouseEnter: () => {
          setOpenCollapseType(tabName);
        },
        onMouseLeave: () => {
          setOpenCollapseType(null);
        },
      };
    }
    return {};
  };

  const banner = React.useMemo(() => {
    if (tab === TABS.DESIGNERS && hashtag) {
      let designer = DesignersArr.find(x => x.name === hashtag);

      return rwd !== "mobile" ? designer.banner : designer.banner_rwd;
    }

    return rwd !== "mobile"
      ? "product_list_banner.webp"
      : "product_list_banner-mobile.webp";
  }, [rwd, tab, hashtag]);

  return (
    <Wrapper isMobile={pad}>
      <div className="banner">
        <div className="img-container">
          <StaticImage
            filename={banner}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          <div className="banner-tag">
            <div>Shop / 線上訂購</div>
          </div>
        </div>
      </div>

      <SearchBar
        visible={openSearch}
        defaultValue={search}
        onSearch={value => {
          const params = queryString.parse(window.location.search) || {};
          params.search = value;
          let query = queryString.stringify(params);

          navigate(`/products?${query}`);
        }}
      />

      <div className="center-content">
        <div className="tab-panel">
          {rwd === "mobile" || rwd === "pad" ? (
            <Ant.Row style={{ justifyContent: "center" }}>
              <div
                style={{
                  backgroundColor: Theme.colors.blueGreen,
                  padding: "10px 60px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => setShowDrawer(true)}
              >
                商品分類
              </div>
            </Ant.Row>
          ) : (
            <Ant.Row className="tabs">
              {Object.values(TABS).map((tab_name, idx) => (
                <div {...getHoverProps(tab_name)}>
                  <Tab
                    key={idx}
                    selected={tab == tab_name}
                    onClick={() => {
                      navigate(`/products?tab=${tab_name}`);
                    }}
                  >
                    {tab_name}
                    {[TABS.PRODUCTS, TABS.HASHTAGS, TABS.PROMOS].includes(
                      tab_name
                    ) && (
                      <KeyboardArrowUp
                        size={18}
                        style={{
                          marginLeft: 5,
                          transform:
                            tab_name === openCollapseType
                              ? "rotate(180deg)"
                              : "none",
                        }}
                      />
                    )}
                  </Tab>
                  {tab_name === TABS.PRODUCTS ? (
                    <Collapse.Categories
                      show={openCollapseType === TABS.PRODUCTS}
                      hideCollapse={() => setOpenCollapseType(null)}
                      top={60}
                    />
                  ) : tab_name === TABS.HASHTAGS ? (
                    <Collapse.Hashtags
                      show={openCollapseType === TABS.HASHTAGS}
                      hideCollapse={() => setOpenCollapseType(null)}
                      top={60}
                    />
                  ) : tab_name === TABS.PROMOS ? (
                    <Collapse.PromoZones
                      show={openCollapseType === TABS.PROMOS}
                      hideCollapse={() => setOpenCollapseType(null)}
                      top={60}
                    />
                  ) : null}
                </div>
              ))}
            </Ant.Row>
          )}
          {tab !== TABS.DESIGNERS && (
            <div className="header">
              <ProductsTabHeader
                displayTitle={(() => {
                  switch (tab) {
                    case TABS.PRODUCTS:
                      return cat?.display || "全部商品";
                    case TABS.PROMOS:
                      return (
                        promozone?.label ||
                        promoZones[0]?.label ||
                        "尚無任何活動專區"
                      );
                    case TABS.HASHTAGS:
                      return `# ${hashtag}`;
                    default:
                      return tab;
                  }
                })()}
                sort={sort}
                search={search}
                setOpenSearch={setOpenSearch}
                tab={tab}
              />
            </div>
          )}

          <div className="products-grid">
            {true &&
              (() => {
                switch (tab) {
                  case TABS.DESIGNERS:
                    return (
                      <DesignerTab
                        rwd={rwd}
                        products={products}
                        onSelectDesigner={designer =>
                          navigate(
                            `/products?tab=${TABS.DESIGNERS}&designer=${designer}`
                          )
                        }
                      />
                    );
                  default:
                    return <ProductsGrid products={products} />;
                }
              })()}
          </div>
          <div className="pagination"></div>
        </div>
      </div>

      <Ant.Drawer
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        drawerStyle={{
          backgroundColor: Theme.colors.main,
        }}
      >
        <Ant.Row style={{ justifyContent: "flex-end" }}>
          <CloseOutlined
            style={{ color: "#fff", fontSize: 16, textAlign: "right" }}
            onClick={() => setShowDrawer(false)}
          />
        </Ant.Row>
        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          商品分類
        </h3>
        <CategoryMenuItem
          categories={app.state.categories}
          goToCategory={c => {
            setShowDrawer(false);
            navigate(`/products?tab=全部商品&category=${c.name}`);
          }}
        />

        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          設計師專區
        </h3>
        <Designers
          onClick={designer => {
            setShowDrawer(false);
            navigate(`/products?tab=設計師專區&designer=${designer.name}`);
          }}
        />

        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          活動專區
        </h3>
        <PromoZones
          promoZones={promoZones}
          onClick={zone => {
            setShowDrawer(false);
            navigate(`/products?tab=活動專區&promozone=${zone.name}`);
          }}
        />

        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          Hashtags
        </h3>
        <ProductHashtags
          hashtags={app.state.hashtags}
          selected={hashtag}
          onTagClick={hashtag =>
            navigate(`/products?tab=${TABS.HASHTAGS}&hashtag=${hashtag}`)
          }
        />

        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          訂閱制專區
        </h3>
        <MenuItem
          onClick={() => {
            navigate(`/products?tab=${TABS.PERIOD}`);
          }}
        >
          訂閱制專區
        </MenuItem>

        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          客製化專區
        </h3>
        <MenuItem
          onClick={() => {
            navigate(`/products?tab=${TABS.CUSTOMIZE}`);
          }}
        >
          客製化專區
        </MenuItem>

        <h3
          style={{
            color: "white",
            marginTop: 10,
            letterSpacing: 0.9,
            fontSize: 18,
          }}
        >
          客製化貼紙
        </h3>
        <MenuItem
          onClick={() => {
            navigate(`/products?tab=${TABS.STICKER}`);
          }}
        >
          客製化貼紙
        </MenuItem>
      </Ant.Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  & > .banner {
    background-color: #f6f6f6;
    & > .img-container {
      position: relative;
      height: ${props => (props.isMobile ? "250px" : "270px")};
      max-width: ${Theme.centerContentMaxWidth};
      margin: 0px auto;
      & > .banner-tag {
        ${Theme.bannerTagStyle}
      }
    }
  }
  & .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;

    & > .tab-panel {
      padding: ${props => (props.isMobile ? Theme.rwdPadding.medium : 0)};
      & > .tabs {
        position: relative;
        margin-top: 30px;
        border-bottom: 0.5px solid ${Theme.colors.unSelected};
      }
      & > .header {
      }
      & > .products-grid {
        margin-top: 20px;
      }
      & > .pagination {
      }
    }
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.9px;
  height: ${Theme.tabHeight}px;
  margin: 0px 20px;
  cursor: pointer;
  border-bottom: ${props =>
    props.selected ? `0.5px solid ${Theme.colors.selected}` : `none`};
  color: ${props =>
    props.selected ? Theme.colors.selected : Theme.colors.unSelected};

  @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
    margin: 0px 6px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
  cursor: pointer;
  color: #e6e6e6;

  :hover {
    color: #9b9b9b;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ProductListPage);
